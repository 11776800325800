<template>
  <section class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">产品特点</h2>
            <h4 class="sub-title">Highlights of system functions</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div 
          v-for="(item, index ) in list" 
          :key="index"
          class="col-lg-3 col-md-6 col-sm-10">
          <div class="blog-box wow bounceInLeft">
            <div class="blog-img">
              <img :src="item.img" alt="blog-img">
            </div>
            <div class="blog-content">
              <h3 class="bold">{{ item.title }}</h3>
              <p class="gray97" :title="item.content">{{ item.content }} </p>
            </div>
            <div class="blog-btn" :class="index%2 ? 'blog-btn2' : ''">{{ item.title }}</div>
          </div>
        </div>
  
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      list: [
        {
          title: '数据统计',
          content: '系统结合综合管理平台特点，院内所有服务申请都通过同一个出口由电脑分发到相应主管后勤部门，所有的服务信息申请都留有记录，形成医院内部服务申请信息大数据，方便统计查询。',
          img: '/img/blog-img-01.jpg',
        },
        {
          title: '团队协作',
          content: '系统通过满意度评价、电话回访等功能提高各主管后勤部门和各临床科室的工作积极性，打通各科室间以及医院之间的数据壁垒，消除信息孤岛，提高协作效率。',
          img: '/img/blog-img-02.jpg',
        },
        {
          title: '综合管理',
          content: '系统整合医院的人员配置管理、各类物品、品牌设备的使用、维修、报废管理和患者的病例记录管理，更高效地发挥信息化的作用，实现智能化管理。',
          img: '/img/blog-img-03.jpg',
        },
        {
          title: '医患沟通',
          content: '根据患者院后病情变化跟踪，通过手机APP或小程序，对患者进行复诊通知、用药提醒、健康宣教普及等服务，实现医患实时沟通。',
          img: '/img/blog-img-01.jpg',
        },
      ]
    }
  }
}
</script>

<style scoped>
.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 11 Blogs-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding: 80px 0;
}
.container {
  max-width: 1140px;
}
.blog-box {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  height: auto;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.blog-content {
  padding: 0 20px;
}

.blog-content h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.blog-img {
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 30px;
}

.blog-img img {
  border-radius: 8px 8px 0px 0px;
  width: 100%;
    max-width: 100%;
    height: auto;
}

.blog-meta-tag  li span {
  color: #6e88e5;
}

.blog-meta-tag  li {
  display: inline;
  padding-right: 10px;
}

.blog-content ul {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
}

.blog-btn {
  width: 145px;
  height: 40px;
  background-color: #5a68ef;
  color: #FFF;
  display: block;
  text-align: center;
  margin-top: 25px;
  border-radius: 0px 8px 8px 0px;
  line-height: 2.5;
}

.blog-btn:hover {
  background-color: #ff8a41;
  color: #FFF;
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.blog-box.active .blog-btn {
  background-color: #ff8a41;
  color: #FFF;
}

.blog-btn2 {
  background-color: #ff8a41;
  color: #FFF;
}

.blog-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 3;		
	line-clamp: 3;					
	-webkit-box-orient: vertical;
}

/* ----------------------------------------------------------------
     [ End Blogs-section ]
-----------------------------------------------------------------*/

</style>
