<template>
  <div>
    <Section />
    <Modular3 />
    <Modular4 />
    <Modular1 />
    <Modular2 />
  </div>
</template>

<script>
import Section from './components/section/Section.vue'
import Modular1 from './components/modular1/index.vue'
import Modular2 from './components/modular2/index.vue'
import Modular3 from './components/modular3/index.vue'
import Modular4 from './components/modular4/index.vue'

export default {
  name: 'School',
  components: {
    Section,
    Modular1,
    Modular2,
    Modular3,
    Modular4
  },
  mounted() { 
    new this.$wow.WOW().init()
  }
}
</script>

<style scoped>

</style>
