<template>
  <section id="school" style="border-top: solid 120px #fff;margin-top: -120px;" class="ws-section-spacing about-bg">

    <div class="container">
      <div class="row about-content">
        <div class="col-lg-6 col-md-12 wow bounceInLeft">
          <div class="w100 ht350 shadow2 radius10 tc ptb10 plr50">
            <img class="h100 w100" src="/img/zhxy3.png" alt="img">
          </div>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="wt500">
            <div class="left-title">
              <p class="title ft30 bold">▶ 智慧校园平台</p>
              <h4 class="sub-title">应用场景：幼、小、中、高校园</h4>
            </div>
            <div>智慧校园管理平台融合教学、教务、安全、办公OA、学科资源、教师素质、学生综合发展等功能为一体，是学校用于日常教学办公和学校管理的统一的教育管理信息化发布管理平台，系统多级权限设置，信息安全；学校数据共享共创、数据全面、统计分析；有效提升学校信息化建设、提高教师的工作效率、提升校园安全。</div>
            <!-- <ul>
              <li class="about-point"> <span class="dot"></span>Ded do eiusmod tempor incididunt ut labore.</li>
            </ul> -->
            <a href="javascript:;" class="btn" @click="$router.push({ path: '/schoolProduct' })">查看详情 >></a>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
  padding-bottom: 30px;
}

.left-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #596dee;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>