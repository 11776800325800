<template>
  <div>
    <Section />
    <Sidebar />
    <Modular1 />
    <Modular2 />
    <Modular3 />
    <Modular4 />
    <Modular5 />
  </div>
</template>

<script>
import Section from './components/section/Section.vue'
import Sidebar from './components/sidebar/Sidebar.vue'
import Modular1 from './components/modular1/index.vue'
import Modular2 from './components/modular2/index.vue'
import Modular3 from './components/modular3/index.vue'
import Modular4 from './components/modular4/index.vue'
import Modular5 from './components/modular5/index.vue'

export default {
  components: {
    Section,
    Sidebar,
    Modular1,
    Modular2,
    Modular3,
    Modular4,
    Modular5
  }
}
</script>

<style>
</style>
