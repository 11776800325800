<template>
  <section id="bidding" style="border-top: solid 120px #fff;margin-top: -120px;" class="ws-section-spacing about-bg">
    <div class="container">
      <div class="row about-content">
        <div class="col-lg-6 col-md-12 flex column jscenter wow bounceInRight">
          <div class="wt500">
            <div class="left-title">
              <p class="title ft30 bold">▶ 智慧政务平台</p>
              <h4 class="sub-title">应用场景：政府系统</h4>
            </div>
            <div>智慧政务管理平台融合OA办公审批流程、办公中心、管理中心、资源中心、调研与评价中心为一体，将当代政务工作结合信息技术，在互联网上实现组织结构和工作流程的优化重组，打破时间和空间及部门之间的分隔限制，有利于提高政府的办事效率和决策水平。</div>
            <!-- <ul>
              <li class="about-point"><span class="dot"></span>Ded do eiusmod tempor incididunt ut labore.</li>
            </ul> -->
            <a href="javascript:;" class="btn" @click="$router.push({ path: '/governmentProduct' })">查看详情 >></a>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 wow bounceInLeft">
          <div class="w100 ht350 shadow2 radius10 tc ptb10 plr10">
            <img class="h100 w100" src="/img/zhzw3.png" alt="img">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
[ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
  padding-bottom: 30px;
}

.left-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #596dee;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>