<template>
  <div class="colorlib-intro">
    <div class="container wow flipInY">
      <div class="row">
        <div class="col-md-12 box">
          <h2>产品价值</h2>
          <p>通过建立智慧办公体系，搭建OA办公平台，实现对单位的统一信息化管理，打通单位内部各部门以及下属单位之间的信息沟通渠道，实现全单位无纸化办公，提高工作效率，保证单位工作中各类申请、审批、公文流转、邮件等各类业务流程的及时性、便捷性。通过智能会议的互通协作方式，方便单位间业务交流，提高沟通效率。同时，各类操作都有留痕，数据永久保存，方便后期追溯。</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="Mbtn on"><div class="navbtn"><span><i class="fa fa-play" aria-hidden="true"></i></span></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>

.colorlib-intro {
  background: whitesmoke;
  padding: 80px 0;
  clear: both; 
}
.colorlib-intro .container .box{
  text-align: center;
  padding: 0 200px;
}
.colorlib-intro .container .box h2 {
  font-size: 33px; font-weight: 700; color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  margin: 0 0 20px 0;
  line-height: 1.3;
}
.colorlib-intro .container .box p {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  color: gray;
  margin-bottom: 80px;
}

.Mbtn .navbtn{ 
  width: 80px; height: 80px; 
  line-height: 80px; text-align: center; 
  border-radius: 50%; border-radius: 50%; 
  position: relative; z-index: 2; 
  margin: 0 auto;
  cursor: pointer;
}
.Mbtn .navbtn:after{
  content:""; opacity:0;
  width:70px; height:70px; display:block;
  position:absolute; left:50%; top:50%;  z-index:1;
  margin-left:-35px; margin-top:-35px;
  background:#798eea;
  -moz-border-radius:50%;-webkit-border-radius:50%;border-radius:50%;
  animation:scaleAnim1 1.6s infinite linear;
}
.Mbtn .navbtn:before{
  content:""; opacity:0;
  width:80px; height:80px;  display:block;
  position:absolute; left:50%; top:50%; z-index:1;
  margin-left:-40px; margin-top:-40px;
  background:#98aaf7;
  -moz-border-radius:50%;-webkit-border-radius:50%;border-radius:50%;
  animation:scaleAnim2 1.6s infinite linear;
}
.Mbtn .navbtn:before{animation-delay:-.5s}

.Mbtn .navbtn span{ 
  width: 60px; height: 60px; display: block;
  line-height: 60px; font-size: 14px; color: #fff;
  position: absolute; left:50%; top:50%; z-index: 4;
  margin-left: -30px; margin-top: -30px;
}
.Mbtn .navbtn span:before{
  content:"";
  width:40px; height:40px; display:block;
  position:absolute; left:50%; top:50%; 
  border-radius: 50%;
  margin-left: -20px; margin-top: -20px; 
  /* background: #e1e1e1; */
}
.Mbtn .navbtn span:after{
  content:"";
  width:30px; height:30px; display:block;
  position:absolute; left:50%; top:50%;
  border-radius: 50%; 
  margin-left:-15px; margin-top: -15px; 
  line-height: 30px; 
  /* background: #fff; */
  color: #fff 
}

/* .Mbtn:hover .navbtn span:after{background: #079399;color: #fff;}
.Mbtn:hover .navbtn span:before{background: #2ca19d;} */

@keyframes scaleAnim2{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.7);opacity:.4}
  100%{transform:scale(1.4);opacity:0}
}
@keyframes scaleAnim1{
  0%{transform:scale(.3);opacity:0}
  50%{transform:scale(.6);opacity:.8}
  100%{transform:scale(1.6);opacity:0}
}

</style>