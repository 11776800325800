<template>
  <section class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">产品特点</h2>
            <h4 class="sub-title">Highlights of system functions</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div 
          v-for="(item, index ) in list" 
          :key="index"
          class="col-lg-4 col-md-6 col-sm-10">
          <div class="blog-box wow bounceInLeft">
            <div class="blog-img">
              <img :src="item.img" alt="blog-img">
            </div>
            <div class="blog-content">
              <h3 class="bold">{{ item.title }}</h3>
              <p class="gray97" :title="item.content">{{ item.content }} </p>
            </div>
            <div class="blog-btn" :class="index%2 ? 'blog-btn2' : ''">{{ item.title }}</div>
          </div>
        </div>
  
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      list: [
        {
          title: '稳定高效',
          content: '根据用户不同的组织架构和业务处理逻辑进行灵活的系统架构设计，业务持续稳定，系统更加稳健可靠。将日常办公和管理相结合，提高工作效率。',
          // img: require('@/assets/blog-img-01.jpg'),
          img: '/img/blog-img-01.jpg',
        },
        {
          title: '协同办公',
          content: '通过工作流层级审批、协同工作任务等功能，打破事件和空间及部门之间的分隔限制，实现组织结构和工作流程的优化重组。',
          img: '/img/blog-img-02.jpg',
        },
        {
          title: '智慧党建',
          content: '将党的路线方针政策、中央有关精神以及基层党建工作的有关政策信息等，及时下发各基层党组织成员。',
          img: '/img/blog-img-03.jpg',
        }
      ]
    }
  }
}
</script>

<style scoped>
.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 11 Blogs-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding: 80px 0;
}
.container {
  max-width: 1140px;
}
.blog-box {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  height: auto;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.blog-content {
  padding: 0 20px;
}

.blog-content h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.blog-img {
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 30px;
}

.blog-img img {
  border-radius: 8px 8px 0px 0px;
  width: 100%;
    max-width: 100%;
    height: auto;
}

.blog-meta-tag  li span {
  color: #6e88e5;
}

.blog-meta-tag  li {
  display: inline;
  padding-right: 10px;
}

.blog-content ul {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
}

.blog-btn {
  width: 145px;
  height: 40px;
  background-color: #5a68ef;
  color: #FFF;
  display: block;
  text-align: center;
  margin-top: 25px;
  border-radius: 0px 8px 8px 0px;
  line-height: 2.5;
}

.blog-btn:hover {
  background-color: #ff8a41;
  color: #FFF;
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.blog-box.active .blog-btn {
  background-color: #ff8a41;
  color: #FFF;
}

.blog-btn2 {
  background-color: #ff8a41;
  color: #FFF;
}

.blog-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 3;		
	line-clamp: 3;					
	-webkit-box-orient: vertical;
}

/* ----------------------------------------------------------------
     [ End Blogs-section ]
-----------------------------------------------------------------*/

</style>
