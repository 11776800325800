<template>
  <section class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">产品特点</h2>
            <h4 class="sub-title">Highlights of system functions</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div
          v-for="(item, index ) in list" 
          :key="index"
          class="col-lg-12 col-md-12 col-sm-10">
          <div class="blog-box flex wow bounceInLeft">
            <div class="blog-img w30">
              <img :src="item.img" alt="blog-img">
            </div>
            <div class="blog-content w70">
              <h3 class="bold">{{ item.title }}</h3>
              <p class="gray97">{{ item.content }}</p>
              <div class="blog-btn" :class="index%2 ? 'blog-btn2' : ''">{{ item.title }}</div>
            </div>
            
          </div>
        </div>
  
      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      list: [
        {
          title: '智能感知',
          content: '应用智能感知技术随时随地感知、捕获、采集和传递物理校园中教学、科研、管理以及生活相关的各类环境、资源与活动的实时数据，实现对校园各类设施设备的运行状态、师生学习工作生活的活动轨迹、师生与校园环境的互动情况等的全面感知，为智慧校园的全面数据化提供感知支撑。',
          img: '/img/blog-img-01.jpg',
        },
        {
          title: '数据为体',
          content: '数据是支撑智慧校园的根本，是智慧校园的核心资产。智慧校园中人和物的基本状态、各种活动以及环境交互等各类信息要全面数据化，数据要连接化、共享化、要素化、全过程化，数据联接要广泛化，通过连接产生反馈、互动，从而激发“化学反应式”的创新和融合。',
          img: '/img/blog-img-02.jpg',
        },
        {
          title: '流程为相',
          content: '流程是数据得以产生、流转和使用的载体，是智慧校园中教学、科研、管理和生活等活动得以实现的方法和途径。智慧校园可以视为由广大师生和各类设备作为“节点”组成的大规模“实时协同分工网络”，广大师生和各类设备“节点”之间的协同分工通过构建统一的业务流程网络实现。在此过程中， 数据也通过统一的业务流程网络得以产生、流转和使用。',
          img: '/img/blog-img-03.jpg',
        },
        {
          title: '服务为用',
          content: '打破物理校园边界，为广大师生提供个性化、一站式、线上线下相结合的综合服务，解决师生在教学、科研、管理和生活中的实际需求，支撑高校开展个性化人才培养、科学研究、智慧型管理决策以及智慧型生活。',
          img: '/img/blog-img-01.jpg',
        },
      ]
    }
  }
}
</script>

<style scoped>
.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 11 Blogs-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding: 80px 0;
}
.container {
  max-width: 1140px;
}
.blog-box {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 35px 0px rgba(40, 44, 71, 0.15);
  height: 200px;
  /* padding-bottom: 30px; */
  margin-bottom: 30px;
}

.blog-content {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-content h3 {
  margin-bottom: 10px;
  font-size: 18px;
}
.blog-img img {
  border-radius: 8px 8px 0px 0px;
  width: 100%;
    max-width: 100%;
    height: 100%;
}

.blog-meta-tag  li span {
  color: #6e88e5;
}

.blog-meta-tag  li {
  display: inline;
  padding-right: 10px;
}

.blog-content ul {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
}

.blog-btn {
  width: 100px;
  height: 40px;
  background-color: #5a68ef;
  color: #FFF;
  display: block;
  text-align: center;
  margin-top: 10px;
  border-radius: 0px 8px 8px 0px;
  line-height: 2.5;
}

.blog-btn:hover {
  background-color: #ff8a41;
  color: #FFF;
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

.blog-box.active .blog-btn {
  background-color: #ff8a41;
  color: #FFF;
}
.blog-btn2 {
  background-color: #ff8a41;
  color: #FFF;
}

/* ----------------------------------------------------------------
     [ End Blogs-section ]
-----------------------------------------------------------------*/

</style>
