<template>
  <nav :class="nav ? 'colorlib-nav': 'colorlib-nav nav2'">
    <div class="top-menu">
      <div class="container">
        <div class="row">
          <div class="col-md-2">
            <!-- <div id="colorlib-logo"><router-link to="/"><i class="fa fa-paper-plane-o" aria-hidden="true"></i> 惠云软件</router-link></div> -->
            <div id="colorlib-logo">
              <router-link to="/">
                <img src="@/assets/logo2.png" class="wt120">
              </router-link>
            </div>
          </div>
          <div class="col-md-10 tr menu-1">
            <ul>
              <li v-for="(item, index) in navList" :key="index" :class=" item.route == currNav ? 'active' : ''">
                <span @click="handleLink(index, item.route)">{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      navList: [
        { name: '我们的产品', route: '/productHome' },
        { name: '智慧校园', route: '/schoolProduct' },
        { name: '智慧政务', route: '/governmentProduct' },
        { name: '智慧医疗', route: '/medicalTreatmentProduct' },
        { name: '隐患管理', route: '/hiddenDangerProduct' },
        { name: '实验室管理', route: '/laboratoryProduct' }
      ],
      currNav: '/productHome',
      nav: true
    }
  },
  watch: {
    '$route': {
      handler(to){
        this.currNav = to.path;
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop == 0) {
        this.nav = true;
        return;
      } else {
        this.nav = false
      }
    },
    handleLink(index, path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

.nav2 {
  background-color: #597fdd !important;
  box-shadow: 0px 0px 13px rgb(1 12 79 / 36%);
}

.colorlib-nav {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  margin: 0 auto;
}
    
.colorlib-nav .top-menu {
  padding: 30px 30px; 
}

.colorlib-nav #colorlib-logo {
  font-size: 26px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700; 
}
.colorlib-nav #colorlib-logo a {
  position: relative;
  color: #fff; 
}

.colorlib-nav ul {
  padding: 0;
  margin: 0; 
  display: flex;
  justify-content: flex-end;
}
.colorlib-nav ul li {
  /* padding: 8px 0; */
  margin: 0;
  list-style: none;
  /* display: inline; */
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px; 
  display: flex;
  align-content: center;
  margin-left: 20px;
}
.colorlib-nav ul li span {
  position: relative;
  padding: 20px 12px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: bold;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; 
  cursor:pointer;
}
.colorlib-nav ul li span:hover {
  color: #fff; 
}
.colorlib-nav ul li.active > span {
  /* color: rgba(255, 255, 255, 0.5); */
  color: #fff;
  position: relative; 
  z-index: 1;
}
/* .colorlib-nav ul li.active > span:after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); 
} */
.colorlib-nav ul li.active > span:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  right: 0;
  bottom: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #80bf63;
  /* border-radius: 5px; */
}

@media screen and (max-width: 768px) {
  .colorlib-nav { margin: 0; } 
  .colorlib-nav .top-menu { padding: 28px 1em; } 
  .colorlib-nav .menu-1 { display: none; }
}

</style>
