<template>
  <section class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">产品功能</h2>
            <h4 class="sub-title">System implementation function</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg">
                <i class="fa" :class="item.icon" aria-hidden="true"></i>
              </span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '基础数据管理',
          content: '对象分类、安全检查项、作业步骤、位置信息、责任划分',
          icon: 'fa-cubes'
        },
        {
          title: '风险分级管理',
          content: '区域管理、场所管理、设备管理、设施管理、作业活动管理、有害物质管理、风险点管理、风险点辨识对象、检测标准及违规风险、违规风险评价、风险四色图',
          icon: 'fa-cubes'
        },
        {
          title: '隐患排查治理',
          content: '隐患排查项管理、基础管理类排查清单、作业现场类排查清单、隐患排查任务、定时排查任务、扫码上传、NFC感应、隐患台账',
          icon: 'fa-cubes'
        },
        {
          title: '隐患跟踪',
          content: '隐患治理发起、隐患确认、隐患整改、隐患验收、跟踪记录',
          icon: 'fa-cubes'
        },
        {
          title: '风险隐患数据统计',
          content: '风险分析、隐患数据、风险四色图',
          icon: 'fa-cubes'
        }
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding: 40px 0 100px 0;
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  height: 126px;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #ff8a41;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #ff8a41;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
