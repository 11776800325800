<template>
  <section class="ws-section-spacing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-title">
            <h2 class="title">产品功能</h2>
            <h4 class="sub-title">System implementation function</h4>
          </div>
        </div>
      </div>
      <div class="row">

        <div v-for="(item, index) in list" :key="index" class="col-lg-4 col-md-6">
          <div class="service-box wow flipInX">
            <div class="service-icon">
              <span class="service-icon-bg">
                <i class="fa" :class="item.icon" aria-hidden="true"></i>
              </span>
            </div>
            <div class="service-content">
              <h4 class="mb10 bold ft18">{{ item.title  }}</h4>
              <p :title="item.content">{{ item.content  }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      list: [
        {
          title: '样品管理',
          content: '委托登记、委托单位信息、客户管理、样品制备',
          icon: 'fa-cubes'
        },
        {
          title: '检测管理',
          content: '任务分配、任务确认、检测领样、样品检测、数据校核、数据复核、余样管理、检测项管理',
          icon: 'fa-cubes'
        },
        {
          title: '报告管理',
          content: '报告编制分配、报告编制、报告审核、报告批准、报告打印',
          icon: 'fa-cubes'
        },
        {
          title: '计量校准',
          content: '器具检定、器具审核、器具审批、证书打印、器具入库、进度查询、报价管理、标准器具管理',
          icon: 'fa-cubes'
        },
        {
          title: '便捷办公',
          content: '“码”上可查、电子签名/签章管理、自动签名/盖章设置、在线签名、任务安排、视频会议、应急指挥流程措施、个人任务',
          icon: 'fa-cubes'
        },
        {
          title: '人事管理',
          content: '人员管理、岗位规划、考勤管理、薪资管理、保险福利、合同管理、报表统计、规章制度、组织架构管理',
          icon: 'fa-cubes'
        },
        {
          title: '总务管理',
          content: '报修管理、设备管理、类别管理、物品管理、用品管理、固定资产、采购计划、入库、库存、出库、借还、数据导入、统计分析',
          icon: 'fa-cubes'
        },
        {
          title: '财务管理',
          content: '收费标准、收扣退费、批量收费、收费分析、财政收入管理、工资管理、支出审批、经营分析',
          icon: 'fa-cubes'
        },
        {
          title: '信息管理',
          content: '政务新闻、通知公告、规章制度、学习活动、检查通报',
          icon: 'fa-cubes'
        },
        {
          title: '数据统计',
          content: '检测数据统计、检测结果分析统计、订单统计、样品统计、环节流转、报告效率、设备数据、库房数据、泄露监测、温湿度监测、监控画面管理、烟火温感监测、监控预警',
          icon: 'fa-cubes'
        },
      ]
    }
  }
}
</script>

<style scoped>

.center-title {
  text-align: center;
  padding-bottom: 60px;
}
h2 {
  font-size: 33px;
  line-height: 48px;
  margin-bottom: 0px;
  font-weight: 700;
}

.center-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.center-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  max-width: 750px;
  margin: 0 auto;
  font-weight: 500;
}

/* ----------------------------------------------------------------
     [ 05 Service-section ]
-----------------------------------------------------------------*/
.ws-section-spacing {
  padding: 40px 0 100px 0;
}

.service-icon span.service-icon-bg i:before {
  font-size: 30px;
}

.service-box {
  height: 126px;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 24px 22px 0px;
  margin-bottom: 30px;
  box-shadow: 0px 3px 22px 0px rgba(40, 44, 71, 0.12);
}

.service-icon {
  width: 30%;
  float: left;
  margin-top: 10px;
  margin-bottom: 12px;
}

.icon {
  width: 86px;
  height: 66px;
  background-color: #ff8a41;
}

.service-content {
  width: 68%;
  margin-left: auto;
}
.service-content p{
  text-overflow: -o-ellipsis-lastline;
	overflow: hidden;			
	text-overflow: ellipsis;	
	display: -webkit-box;			
	-webkit-line-clamp: 2;		
	line-clamp: 2;					
	-webkit-box-orient: vertical;
}

.service-icon-bg {
  background-color: #ff8a41;
  width: 86px;
  height: 66px;
  padding-left: 15px;
  display: block;
  font-size: 33px;
  text-align: center;
  line-height: 2;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

</style>
