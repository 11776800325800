<template>
  <section id="laboratory" style="border-top: solid 120px #fff;margin-top: -120px;" class="ws-section-spacing about-bg">
    <div class="container">
      <div class="row about-content">
        <!-- <div class="col-lg-6 col-md-12 flex alcenter jscenter wow bounceInLeft">
          <img class="w100" style="height:auto; image-rendering: pixelated;" src="@/assets/service-img2.png" alt="img">
        </div> -->
        <div class="col-lg-6 col-md-12 wow bounceInLeft">
          <div class="w100 ht350 shadow2 radius10 tc ptb10 plr10">
            <img class="h100" src="/img/sys2.png" alt="img">
          </div>
        </div>
        <div class="col-lg-6 col-md-12 flex column jscenter flexend wow bounceInRight">
          <div class="wt500">
            <div class="left-title">
              <p class="title ft30 bold">▶ 实验室管理平台 </p>
              <h4 class="sub-title">应用场景：检验检测、教育、科研、化工等</h4>
            </div>
            <div>实验室管理系统以实验室为中心的分布式管理体系，集样品检测流程、资源管理、事务管理、数据管理、报表管理、人员管理、设备器材管理等诸多功能为一体.组成一套完整的实验室综合管理和质量监控系统，对实验室实行全方位的管理和控制，从而使实验室的最终产品，即所有的检测或管理数据、信息均符合相关的质量标准或规范。</div>
            <!-- <ul>
              <li class="about-point"> <span class="dot"></span>Ded do eiusmod tempor incididunt ut labore.</li>
            </ul> -->
            <a href="javascript:;" class="btn" @click="$router.push({ path: '/laboratoryProduct' })">查看详情 >></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  
}
</script>
<style scoped>
/* ----------------------------------------------------------------
     [ 06 About-section ]
-----------------------------------------------------------------*/
h2 {
    font-size: 33px;
    line-height: 48px;
    margin-bottom: 0px;
    font-weight: 700;
}

.about-bg {
  /* background-image: url('~@/assets/about-bg.png');
  background-repeat: no-repeat; */
  padding: 90px 0 45px 0;
}


.left-title {
  text-align: left;
  padding-bottom: 30px;
}

.left-title .title {
  text-transform: capitalize;
  padding-bottom: 30px;
}

.left-title .sub-title {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin: 0 auto;
  font-weight: 600;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 12px;
  border: 2px solid #596dee;
  display: inline-block;
  margin-right: 10px;
}

.about-point {
  font-weight: 600;
  padding-bottom: 15px;
}

.btn {
  border-radius: 8px;
  background-color: #ff8a41;
  margin: 40px 0 30px 0;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 12px 40px;
  width: 50%;
}

.btn:hover {
  background-color: #596dee;
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

/* ----------------------------------------------------------------
     [ End About-section ]
-----------------------------------------------------------------*/
</style>