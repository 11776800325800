<template>
  <div class="sidebar">
    <a href="#school" class="item bg1">校园</a>
    <a href="#bidding" class="item bg2">政务</a>
    <a href="#medicalTreatment" class="item bg3">医疗</a>
    <a href="#hiddenDangerManage" class="item bg4">隐患</a>
    <a href="#laboratory" class="item bg5">实验</a>
  </div>
</template>
<script>
export default {
  
}
</script>
<style scoped>
.sidebar {
  position: fixed;
  right: 30px;
	top: 50%;
  transform: translateY(-50%);
  padding-top:100px; margin-top:-100px;
}
.sidebar .item {
  display: block;
  width: 50px;
  height: 50px;
  background: red;
  border-radius: 50%;
  margin-bottom: 20px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  cursor:pointer;
  box-shadow: 0 0.09rem 0.18rem 0 rgb(0 0 0 / 15%);
}
.sidebar .bg1 {
  background: #f2bb51;
}
.sidebar .bg2 {
  background: #55bd5c;
}
.sidebar .bg3 {
  background: #39c1eb;
}
.sidebar .bg4 {
  background: #4e7cdd;
}
.sidebar .bg5 {
  background: hsl(312, 56%, 74%);
}
.sidebar .item:hover{
	-webkit-transform:rotate(0deg) scale(1.3);
	-ms-transform:rotate(0deg) scale(1.3);
	transform:rotate(0deg) scale(1.3);
	-webkit-transition:all 0.5s ease-in;
	transition:all 0.5s ease-in;
}
</style>